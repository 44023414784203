@import "~react-toastify/dist/ReactToastify.css";

@tailwind components;
@tailwind base;
@tailwind utilities;

@font-face {
  font-family: 'Google-Sans-Regular';
  src: url('../fonts/GoogleSans-Regular.woff2') format('woff2'),
  url('../fonts/GoogleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Google-Sans-Bold';
  src: url('../fonts/GoogleSans-Bold.woff2') format('woff2'),
  url('../fonts/GoogleSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



body {
  font-family: 'Google-Sans-Regular', serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 120%;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 18px;
}

b {
  font-family: 'Google-Sans-Bold', serif;
}

textarea, input {
  &:focus {
    outline: none;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
}

.bg-gradient {
  background: linear-gradient(192.05deg, #FFFFFF 0%, #FFF1E6 51.56%, #F48FAC 100%);
}

.Toastify__toast-body {
  @apply font-primary text-18;
}
